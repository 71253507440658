import 'styles/header.less';
import React from 'reactn';

const Header = (props) => {
  return (
    <div className={"header " + (props.viewHasHeader ? 'header--show' : 'header--hide')}>
      <button className="header__logout" onClick={props.logoutAction}>
        <span>Log Out</span>
      </button>
      <div className="header__user">
        <p className="header__user_name">
          {props.user.name}
        </p>
        <div className="header__user_avatar">
          <img className="header__user_image" src={props.user.avatar} alt={props.user.name}/>
        </div>
      </div>
    </div>
  );
}

export default Header;
