import 'styles/core.less';
import React from 'reactn';

const ActivityIndicator = (props) => {
  return (
    <div className={"activity " + (props.shouldDisplay ? 'activity--show' : 'activity--hide')}>
      <div className="activity__spinner"></div>
    </div>
  );
}

export default ActivityIndicator;
