import React, {PureComponent} from 'reactn';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import ActivityIndicator from 'components/activityindicator';
import Header from 'components/header';
import Breadcrumbs from 'components/breadcrumbs';
import asyncComponent from 'components/asynccomponent';

/* Lazy Routes/Components */
const Login = asyncComponent(() =>
  import('pages/login')
    .then(module => module.default)
);

const Welcome = asyncComponent(() =>
  import('pages/welcome')
    .then(module => module.default)
);

const Start = asyncComponent(() =>
  import('pages/start')
    .then(module => module.default)
);

const Pathway = asyncComponent(() =>
  import('pages/pathway')
    .then(module => module.default)
);

const Symptoms = asyncComponent(() =>
  import('pages/symptoms')
    .then(module => module.default)
);

const Modalities = asyncComponent(() =>
  import('pages/modalities')
    .then(module => module.default)
);

const Results = asyncComponent(() =>
  import('pages/results')
    .then(module => module.default)
);

const InformationOnly = asyncComponent(() =>
  import('pages/informationonly')
    .then(module => module.default)
);

const LocationInfo = asyncComponent(() =>
  import('pages/locationinfo')
    .then(module => module.default)
);

const Satisfaction = asyncComponent(() =>
  import('pages/satisfaction')
    .then(module => module.default)
);

const Confirm = asyncComponent(() =>
  import('pages/confirm')
    .then(module => module.default)
);

const Finalize = asyncComponent(() =>
  import('pages/finalize')
    .then(module => module.default)
);

const Done = asyncComponent(() =>
  import('pages/done')
    .then(module => module.default)
);

// Build a PrivateRoute function that validates the user token upon each route taken. If the token is invalid or has expired, the user is redirected to log in again.
const PrivateRoute = ({component: PureComponent, isAuthenticated, ...rest}) => (
  <Route {...rest} render={(props) => (
    isAuthenticated
      ? <PureComponent {...props} />
      : <Redirect to='/'/>
  )}/>
);

// Build an opposite custom route chunk, specifically for the log in screen - that will push a user to the Welcome screen if they are already authenticated
const AlreadyAuthenticatedRoute = ({component: PureComponent, isAuthenticated, ...rest}) => (
  <Route {...rest} render={(props) => (
    isAuthenticated
      ? <Redirect to='/welcome'/>
      : <PureComponent {...props} />
  )}/>
);

class App extends PureComponent {

  constructor(props) {
    super(props);

    this.handleCrumbAction = this.handleCrumbAction.bind(this);
    this.handleLogOutRequest = this.handleLogOutRequest.bind(this);
  }

  componentWillMount() {

    //Does a token exist in the localStorage? Let's grab it
    if (localStorage.getItem("token") !== null) {
      // Update the central app state with the token
      this.global.user.token = localStorage.getItem("token");
    }
    // Update the authentication status
    this.isAuthenticated();

  }

  componentDidCatch(error, errorInfo) {

  }

  async isAuthenticated() {

    let token = '';

    if (this.global.user.token !== null) {

      token = this.global.user.token;

    } else {

      if (localStorage.getItem("token") !== null) {
        // Update the central app state with the token
        this.global.user.token = localStorage.getItem("token");
        token = this.global.user.token;

      }
    }

    // Was a token found?
    if (token) {

      // Validate the token
      let response = await this.validateToken(token);

      // Was it a good response?
      if (response.data.status === 200) {

        // Update the global state
        this.setGlobal((prevState) => ({
          ...prevState,
          user: {
            ...prevState.user,
            isAuthenticated: true,
            token: token
          }
        }));

        this.updateUserDetails(token);
      } else {
        // TODO: how to handle?
      }
    } else {
      // TODO: how to handle?
    }
  }

  validateToken(token) {
    return new Promise((resolve, reject) => {
      fetch('/wp-json/jwt-auth/v1/token/validate', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
        .then((response) => {
          resolve(response.json());
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateUserDetails(token) {
    let response = await this.fetchUserDetails(token);
    if (response.status === 200 && response.result !== null) {

      // Update the global state user object
      this.setGlobal((prevState) => ({
        ...prevState,
        user: {
          ...prevState.user,
          isAuthenticated: true,
          token: token,
          id: response.result.id,
          name: response.result.firstName + ' ' + response.result.lastName,
          avatar: response.result.avatarURL,
          locations: response.result.locations
        }
      }));
    } else {
      // TODO: How to handle?
    }
  }

  fetchUserDetails(token) {
    return new Promise((resolve, reject) => {
      fetch('/wp-json/upmc-na/v1/user', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
        .then((response) => {
          resolve(response.json());
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Callback prop function to handle click/tap actions taken on the Breadcrumb buttons
  handleCrumbAction(evt) {
    const route = evt.currentTarget.name;

    this.props.history.push('/' + route);
  }

  // Callback prop function to handle click/tap actions taken on the "Logout" button in the Header
  handleLogOutRequest(evt) {

    // Empty/reset the global `user` state
    this.setGlobal({
      user: {
        isAuthenticated: false,
        token: '',
        name: '',
        avatar: '',
        locations: []
      }
    });

    // Remove the auth token from localStorage
    localStorage.removeItem("token");

    // Redirect user back to the log in screen
    this.props.history.push('/');
  }

  render() {
    return (
      <React.Fragment>
        <ActivityIndicator shouldDisplay={this.global.showActivityIndicator}/>
        <Header viewHasHeader={this.global.viewHasHeader} logoutAction={this.handleLogOutRequest}
                user={this.global.user}/>
        <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={300}
              classNames='routeTransition'
            >
              <Switch location={location}>
                <AlreadyAuthenticatedRoute path="/" component={Login} isAuthenticated={this.global.user.isAuthenticated}
                                           exact/>
                <PrivateRoute path="/welcome" component={Welcome} isAuthenticated={this.global.user.isAuthenticated}
                              exact/>
                <PrivateRoute path="/start" component={Start} isAuthenticated={this.global.user.isAuthenticated} exact/>
                <PrivateRoute path="/pathway" component={Pathway} isAuthenticated={this.global.user.isAuthenticated}
                              exact/>
                <PrivateRoute path="/symptoms" component={Symptoms} isAuthenticated={this.global.user.isAuthenticated}
                              exact/>
                <PrivateRoute path="/modalities" component={Modalities}
                              isAuthenticated={this.global.user.isAuthenticated} exact/>
                <PrivateRoute path="/results" component={Results} isAuthenticated={this.global.user.isAuthenticated}
                              exact/>
                <PrivateRoute path="/informationonly" component={InformationOnly}
                              isAuthenticated={this.global.user.isAuthenticated} exact/>
                <PrivateRoute path="/location" component={LocationInfo}
                              isAuthenticated={this.global.user.isAuthenticated} exact/>
                <PrivateRoute path="/satisfaction" component={Satisfaction}
                              isAuthenticated={this.global.user.isAuthenticated}/>
                <PrivateRoute path="/confirm" component={Confirm} isAuthenticated={this.global.user.isAuthenticated}
                              exact/>
                <PrivateRoute path="/finalize" component={Finalize} isAuthenticated={this.global.user.isAuthenticated}
                              exact/>
                <PrivateRoute path="/done" component={Done} isAuthenticated={this.global.user.isAuthenticated} exact/>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}/>
        <Breadcrumbs viewHasBreadcrumbs={this.global.viewHasBreadcrumbs} breadcrumbs={this.global.breadcrumbs}
                     crumbAction={this.handleCrumbAction}/>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
