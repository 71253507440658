import React from 'reactn';
import 'styles/breadcrumbs.less';

const Breadcrumbs = (props) => {
  return (
    <div className={"breadcrumbs " + (props.viewHasBreadcrumbs ? 'breadcrumbs--show' : 'breadcrumbs--hide')}>
      <button className="breadcrumbs__crumb" type="button" name="start" onClick={props.crumbAction}>
        Start Over
      </button>
      {props.breadcrumbs.map((crumb) => {
        return (
          <button key={crumb.route} className="breadcrumbs__crumb " type="button" name={crumb.route}
                  onClick={props.crumbAction}>
            {crumb.name}
          </button>
        )
      })}
    </div>
  );
}

export default Breadcrumbs;

